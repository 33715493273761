html, body {
  margin: 0;
  padding: 0;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-bottom-4 {
  bottom: -1rem;
}

.bottom-10 {
  bottom: 2.5rem;
}

.left-0 {
  left: 0;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-12 {
  margin-left: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.flex {
  display: -ms-flexbox;
  display: flex;
}

.grid {
  display: grid;
}

.h-12 {
  height: 3rem;
}

.h-24 {
  height: 6rem;
}

.h-\[90vh\] {
  height: 90vh;
}

.h-screen {
  height: 100vh;
}

.max-h-\[60vh\] {
  max-height: 60vh;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-96 {
  width: 24rem;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-\[1000px\] {
  max-width: 1000px;
}

.flex-1 {
  -ms-flex: 1;
  flex: 1;
}

.resize {
  resize: both;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-\[100px_1fr\] {
  grid-template-columns: 100px 1fr;
}

.grid-cols-\[100px_1fr_20px\] {
  grid-template-columns: 100px 1fr 20px;
}

.grid-cols-\[120px_1fr_120px_1fr\] {
  grid-template-columns: 120px 1fr 120px 1fr;
}

.flex-row {
  -ms-flex-direction: row;
  flex-direction: row;
}

.flex-col {
  -ms-flex-direction: column;
  flex-direction: column;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.items-start {
  -ms-flex-align: start;
  align-items: flex-start;
}

.items-center {
  -ms-flex-align: center;
  align-items: center;
}

.justify-start {
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.justify-center {
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-between {
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-1 {
  gap: .25rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-6 {
  row-gap: 1.5rem;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.border {
  border-width: 1px;
}

.\!bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}

.\!bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(34, 197, 94, var(--tw-bg-opacity)) !important;
}

.\!bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity)) !important;
}

.bg-slate-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(203, 213, 225, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.pr-1 {
  padding-right: .25rem;
}

.text-center {
  text-align: center;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-semibold {
  font-weight: 600;
}

.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.\!outline {
  outline-style: solid !important;
}

.\!outline-4 {
  outline-width: 4px !important;
}

.\!outline-offset-\[-2px\] {
  outline-offset: -2px !important;
}

.\!outline-orange-500\/80 {
  outline-color: rgba(249, 115, 22, .8) !important;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.ant-upload.ant-upload-select {
  width: 100%;
}

.ant-table-thead th {
  text-align: center !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-table-wrapper .ant-table.ant-table-small {
  font-size: 12px;
}

.ant-table-tbody tr:nth-child(2n) {
  background-color: #f6f6f6;
}

table td, table th {
  border-bottom-color: #e3e3e3 !important;
  border-top-color: #e3e3e3 !important;
}

table td:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)), table th:not(:lang(ae)):not(:lang(ar)):not(:lang(arc)):not(:lang(bcc)):not(:lang(bqi)):not(:lang(ckb)):not(:lang(dv)):not(:lang(fa)):not(:lang(glk)):not(:lang(he)):not(:lang(ku)):not(:lang(mzn)):not(:lang(nqo)):not(:lang(pnb)):not(:lang(ps)):not(:lang(sd)):not(:lang(ug)):not(:lang(ur)):not(:lang(yi)) {
  border-left-color: #e3e3e3;
  border-right-color: #e3e3e3;
}

table td:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)), table th:is(:lang(ae), :lang(ar), :lang(arc), :lang(bcc), :lang(bqi), :lang(ckb), :lang(dv), :lang(fa), :lang(glk), :lang(he), :lang(ku), :lang(mzn), :lang(nqo), :lang(pnb), :lang(ps), :lang(sd), :lang(ug), :lang(ur), :lang(yi)) {
  border-left-color: #e3e3e3;
  border-right-color: #e3e3e3;
}

.ant-table-body {
  overflow-y: auto !important;
}

:where(.css-dev-only-do-not-override-k83k30).ant-layout {
  background: #f8f8f8 !important;
}

.ant-table-container {
  border: 1px solid #d5d5d5 !important;
}

@media (min-width: 640px) {
  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:ml-4 {
    margin-left: 1rem;
  }

  .sm\:flex {
    display: -ms-flexbox;
    display: flex;
  }

  .sm\:grid {
    display: grid;
  }

  .sm\:w-40 {
    width: 10rem;
  }

  .sm\:w-44 {
    width: 11rem;
  }

  .sm\:w-64 {
    width: 16rem;
  }

  .sm\:w-72 {
    width: 18rem;
  }

  .sm\:w-80 {
    width: 20rem;
  }

  .sm\:w-96 {
    width: 24rem;
  }

  .sm\:max-w-3xl {
    max-width: 48rem;
  }

  .sm\:max-w-sm {
    max-width: 24rem;
  }

  .sm\:flex-none {
    -ms-flex: none;
    flex: none;
  }

  .sm\:grid-flow-row {
    grid-auto-flow: row;
  }

  .sm\:grid-cols-\[100px_1fr_100px_1fr\] {
    grid-template-columns: 100px 1fr 100px 1fr;
  }

  .sm\:flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .sm\:flex-row-reverse {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .sm\:justify-end {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media (min-width: 768px) {
  .md\:ml-4 {
    margin-left: 1rem;
  }

  .md\:w-96 {
    width: 24rem;
  }

  .md\:max-w-\[100px\] {
    max-width: 100px;
  }

  .md\:max-w-\[120px\] {
    max-width: 120px;
  }

  .md\:max-w-\[160px\] {
    max-width: 160px;
  }

  .md\:max-w-\[200px\] {
    max-width: 200px;
  }

  .md\:max-w-\[300px\] {
    max-width: 300px;
  }
}

@media (min-width: 1024px) {
  .lg\:flex-row {
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

:root {
  --react-pdf-annotation-layer: 1;
  --annotation-unfocused-field-background: url("data:image/svg+xml;charset=UTF-8,<svg width='1px' height='1px' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill:rgba(0, 54, 255, 0.13);'/></svg>");
  --input-focus-border-color: Highlight;
  --input-focus-outline: 1px solid Canvas;
  --input-unfocused-border-color: transparent;
  --input-disabled-border-color: transparent;
  --input-hover-border-color: black;
}

@media (forced-colors: active) {
  :root {
    --input-focus-border-color: CanvasText;
    --input-unfocused-border-color: ActiveText;
    --input-disabled-border-color: GrayText;
    --input-hover-border-color: Highlight;
  }

  .annotationLayer .textWidgetAnnotation input:required, .annotationLayer .textWidgetAnnotation textarea:required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation.checkBox input:required, .annotationLayer .buttonWidgetAnnotation.radioButton input:required {
    outline: 1.5px solid selectedItem;
  }
}

.annotationLayer {
  pointer-events: none;
  transform-origin: 0 0;
  --scale-factor: 1;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer section {
  text-align: initial;
  pointer-events: auto;
  box-sizing: border-box;
  transform-origin: 0 0;
  position: absolute;
}

.annotationLayer .linkAnnotation > a, .annotationLayer .buttonWidgetAnnotation.pushButton > a {
  width: 100%;
  height: 100%;
  font-size: 1em;
  position: absolute;
  top: 0;
  left: 0;
}

.annotationLayer .buttonWidgetAnnotation.pushButton > canvas {
  width: 100%;
  height: 100%;
}

.annotationLayer .linkAnnotation > a:hover, .annotationLayer .buttonWidgetAnnotation.pushButton > a:hover {
  opacity: .2;
  background: #ff0;
  box-shadow: 0 2px 10px #ff0;
}

.annotationLayer .textAnnotation img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer .textWidgetAnnotation input, .annotationLayer .textWidgetAnnotation textarea, .annotationLayer .choiceWidgetAnnotation select, .annotationLayer .buttonWidgetAnnotation.checkBox input, .annotationLayer .buttonWidgetAnnotation.radioButton input {
  background-image: var(--annotation-unfocused-field-background);
  border: 2px solid var(--input-unfocused-border-color);
  box-sizing: border-box;
  font: calc(9px * var(--scale-factor)) sans-serif;
  height: 100%;
  vertical-align: top;
  width: 100%;
  margin: 0;
}

.annotationLayer .textWidgetAnnotation input:required, .annotationLayer .textWidgetAnnotation textarea:required, .annotationLayer .choiceWidgetAnnotation select:required, .annotationLayer .buttonWidgetAnnotation.checkBox input:required, .annotationLayer .buttonWidgetAnnotation.radioButton input:required {
  outline: 1.5px solid red;
}

.annotationLayer .choiceWidgetAnnotation select option {
  padding: 0;
}

.annotationLayer .buttonWidgetAnnotation.radioButton input {
  border-radius: 50%;
}

.annotationLayer .textWidgetAnnotation textarea {
  resize: none;
}

.annotationLayer .textWidgetAnnotation input[disabled], .annotationLayer .textWidgetAnnotation textarea[disabled], .annotationLayer .choiceWidgetAnnotation select[disabled], .annotationLayer .buttonWidgetAnnotation.checkBox input[disabled], .annotationLayer .buttonWidgetAnnotation.radioButton input[disabled] {
  border: 2px solid var(--input-disabled-border-color);
  cursor: not-allowed;
  background: none;
}

.annotationLayer .textWidgetAnnotation input:hover, .annotationLayer .textWidgetAnnotation textarea:hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation.checkBox input:hover, .annotationLayer .buttonWidgetAnnotation.radioButton input:hover {
  border: 2px solid var(--input-hover-border-color);
}

.annotationLayer .textWidgetAnnotation input:hover, .annotationLayer .textWidgetAnnotation textarea:hover, .annotationLayer .choiceWidgetAnnotation select:hover, .annotationLayer .buttonWidgetAnnotation.checkBox input:hover {
  border-radius: 2px;
}

.annotationLayer .textWidgetAnnotation input:focus, .annotationLayer .textWidgetAnnotation textarea:focus, .annotationLayer .choiceWidgetAnnotation select:focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  background: none;
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus, .annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

.annotationLayer .buttonWidgetAnnotation.checkBox :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
  border-radius: 2px;
}

.annotationLayer .buttonWidgetAnnotation.radioButton :focus {
  border: 2px solid var(--input-focus-border-color);
  outline: var(--input-focus-outline);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after, .annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  background-color: CanvasText;
  content: "";
  display: block;
  position: absolute;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before, .annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  height: 80%;
  width: 1px;
  left: 45%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:before {
  transform: rotate(45deg);
}

.annotationLayer .buttonWidgetAnnotation.checkBox input:checked:after {
  transform: rotate(-45deg);
}

.annotationLayer .buttonWidgetAnnotation.radioButton input:checked:before {
  height: 50%;
  width: 50%;
  border-radius: 50%;
  top: 20%;
  left: 30%;
}

.annotationLayer .textWidgetAnnotation input.comb {
  padding-left: 2px;
  padding-right: 0;
  font-family: monospace;
}

.annotationLayer .textWidgetAnnotation input.comb:focus {
  width: 103%;
}

.annotationLayer .buttonWidgetAnnotation.checkBox input, .annotationLayer .buttonWidgetAnnotation.radioButton input {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

.annotationLayer .popupTriggerArea {
  height: 100%;
  width: 100%;
}

.annotationLayer .popupWrapper {
  font-size: calc(9px * var(--scale-factor));
  width: 100%;
  min-width: calc(180px * var(--scale-factor));
  pointer-events: none;
  position: absolute;
}

.annotationLayer .popup {
  max-width: calc(180px * var(--scale-factor));
  box-shadow: 0 calc(2px * var(--scale-factor)) calc(5px * var(--scale-factor)) #888;
  border-radius: calc(2px * var(--scale-factor));
  padding: calc(6px * var(--scale-factor));
  margin-left: calc(5px * var(--scale-factor));
  cursor: pointer;
  font: message-box;
  white-space: normal;
  word-wrap: break-word;
  pointer-events: auto;
  background-color: #ff9;
  position: absolute;
}

.annotationLayer .popup > * {
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .popup h1 {
  display: inline-block;
}

.annotationLayer .popupDate {
  margin-left: calc(5px * var(--scale-factor));
  display: inline-block;
}

.annotationLayer .popupContent {
  margin-top: calc(2px * var(--scale-factor));
  padding-top: calc(2px * var(--scale-factor));
  border-top: 1px solid #333;
}

.annotationLayer .richText > * {
  white-space: pre-wrap;
  font-size: calc(9px * var(--scale-factor));
}

.annotationLayer .highlightAnnotation, .annotationLayer .underlineAnnotation, .annotationLayer .squigglyAnnotation, .annotationLayer .strikeoutAnnotation, .annotationLayer .freeTextAnnotation, .annotationLayer .lineAnnotation svg line, .annotationLayer .squareAnnotation svg rect, .annotationLayer .circleAnnotation svg ellipse, .annotationLayer .polylineAnnotation svg polyline, .annotationLayer .polygonAnnotation svg polygon, .annotationLayer .caretAnnotation, .annotationLayer .inkAnnotation svg polyline, .annotationLayer .stampAnnotation, .annotationLayer .fileAttachmentAnnotation {
  cursor: pointer;
}

.annotationLayer section svg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.annotationLayer .annotationTextContent {
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  position: absolute;
}

.annotationLayer .annotationTextContent span {
  width: 100%;
  display: inline-block;
}

:root {
  --react-pdf-text-layer: 1;
}

.textLayer {
  text-align: initial;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
  forced-color-adjust: none;
  line-height: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.textLayer span, .textLayer br {
  color: rgba(0, 0, 0, 0);
  white-space: pre;
  cursor: text;
  transform-origin: 0 0;
  position: absolute;
}

.textLayer span.markedContent {
  height: 0;
  top: 0;
}

.textLayer .highlight {
  background-color: #b400aa;
  border-radius: 4px;
  margin: -1px;
  padding: 1px;
}

.textLayer .highlight.appended {
  position: initial;
}

.textLayer .highlight.begin {
  border-radius: 4px 0 0 4px;
}

.textLayer .highlight.end {
  border-radius: 0 4px 4px 0;
}

.textLayer .highlight.middle {
  border-radius: 0;
}

.textLayer .highlight.selected {
  background-color: #006400;
}

.textLayer br::selection {
  background: none;
}

.textLayer .endOfContent {
  z-index: -1;
  cursor: default;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: block;
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.textLayer .endOfContent.active {
  top: 0;
}

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0;
}

button.rbc-btn {
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer;
  overflow: visible;
}

button[disabled].rbc-btn {
  cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: stretch;
  align-items: stretch;
  display: -ms-flexbox;
  display: flex;
}

.rbc-calendar *, .rbc-calendar :before, .rbc-calendar :after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

.rbc-abs-full, .rbc-row-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  overflow: hidden;
}

.rbc-rtl {
  direction: rtl;
}

.rbc-off-range {
  color: #999;
}

.rbc-off-range-bg {
  background: #e6e6e6;
}

.rbc-header {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-height: 0;
  border-bottom: 1px solid #ddd;
  -ms-flex: 1 0;
  flex: 1 0;
  padding: 0 3px;
  font-size: 90%;
  font-weight: bold;
  overflow: hidden;
}

.rbc-header + .rbc-header {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-header + .rbc-header {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-button-link {
  color: inherit;
  cursor: pointer;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.rbc-row-content {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 4;
  position: relative;
}

.rbc-row-content-scrollable {
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
  display: none;
}

.rbc-today {
  background-color: #eaf6ff;
}

.rbc-toolbar {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
  display: -ms-flexbox;
  display: flex;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: center;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding: 0 10px;
}

.rbc-toolbar button {
  color: #373a3c;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0;
  padding: .375rem 1rem;
  line-height: normal;
  display: inline-block;
}

.rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

.rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
  color: #373a3c;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.rbc-toolbar button:focus, .rbc-toolbar button:hover {
  color: #373a3c;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.rbc-btn-group {
  white-space: nowrap;
  display: inline-block;
}

.rbc-btn-group > button:first-child:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-btn-group > button:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
  border-radius: 0 4px 4px 0;
}

.rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
  border-radius: 4px 0 0 4px;
}

.rbc-btn-group > button:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.rbc-btn-group button + button {
  margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button + button {
  margin-left: 0;
  margin-right: -1px;
}

.rbc-btn-group + .rbc-btn-group, .rbc-btn-group + button {
  margin-left: 10px;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
  box-sizing: border-box;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  width: 100%;
  text-align: left;
  background-color: #3174ad;
  border: none;
  border-radius: 5px;
  margin: 0;
  padding: 2px 5px;
}

.rbc-slot-selecting .rbc-event, .rbc-slot-selecting .rbc-day-slot .rbc-background-event, .rbc-day-slot .rbc-slot-selecting .rbc-background-event {
  cursor: inherit;
  pointer-events: none;
}

.rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: #265985;
}

.rbc-event:focus, .rbc-day-slot .rbc-background-event:focus {
  outline: 5px auto #3b99fc;
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px rgba(51, 51, 51, .5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rbc-event-continues-later {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rbc-row {
  -ms-flex-direction: row;
  flex-direction: row;
  display: -ms-flexbox;
  display: flex;
}

.rbc-row-segment {
  padding: 0 1px 1px;
}

.rbc-selected-cell {
  background-color: rgba(0, 0, 0, .1);
}

.rbc-show-more {
  z-index: 4;
  height: auto;
  color: #3174ad;
  background-color: rgba(255, 255, 255, .3);
  font-size: 85%;
  font-weight: bold;
  line-height: normal;
}

.rbc-show-more:hover, .rbc-show-more:focus {
  color: #265985;
}

.rbc-month-view {
  width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
  border: 1px solid #ddd;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.rbc-month-header {
  -ms-flex-direction: row;
  flex-direction: row;
  display: -ms-flexbox;
  display: flex;
}

.rbc-month-row {
  height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

.rbc-month-row + .rbc-month-row {
  border-top: 1px solid #ddd;
}

.rbc-date-cell {
  min-width: 0;
  text-align: right;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  padding-right: 5px;
}

.rbc-date-cell.rbc-now {
  font-weight: bold;
}

.rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
}

.rbc-row-bg {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
}

.rbc-day-bg {
  -ms-flex: 1 0;
  flex: 1 0;
}

.rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-day-bg + .rbc-day-bg {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-overlay {
  z-index: 5;
  background-color: #fff;
  border: 1px solid #e5e5e5;
  padding: 10px;
  position: absolute;
  box-shadow: 0 5px 15px rgba(0, 0, 0, .25);
}

.rbc-overlay > * + * {
  margin-top: 1px;
}

.rbc-overlay-header {
  border-bottom: 1px solid #e5e5e5;
  margin: -10px -10px 5px;
  padding: 2px 10px;
}

.rbc-agenda-view {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  vertical-align: top;
  padding: 5px 10px;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  text-transform: lowercase;
  padding-left: 15px;
  padding-right: 15px;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
  border-top: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: left;
  border-bottom: 1px solid #ddd;
  padding: 3px 5px;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: right;
}

.rbc-agenda-time-cell {
  text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
  content: " »";
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
  content: "« ";
}

.rbc-agenda-date-cell, .rbc-agenda-time-cell {
  white-space: nowrap;
}

.rbc-agenda-event-cell {
  width: 100%;
}

.rbc-time-column {
  min-height: 100%;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -ms-flexbox;
  display: flex;
}

.rbc-time-column .rbc-timeslot-group {
  -ms-flex: 1;
  flex: 1;
}

.rbc-timeslot-group {
  min-height: 40px;
  border-bottom: 1px solid #ddd;
  -ms-flex-flow: column;
  flex-flow: column;
  display: -ms-flexbox;
  display: flex;
}

.rbc-time-gutter, .rbc-header-gutter {
  -ms-flex: none;
  flex: none;
}

.rbc-label {
  padding: 0 5px;
}

.rbc-day-slot {
  position: relative;
}

.rbc-day-slot .rbc-events-container {
  margin-right: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
  left: 10px;
  right: 0;
}

.rbc-day-slot .rbc-event, .rbc-day-slot .rbc-background-event {
  max-height: 100%;
  min-height: 20px;
  border: 1px solid #265985;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.rbc-day-slot .rbc-background-event {
  opacity: .75;
}

.rbc-day-slot .rbc-event-label {
  width: auto;
  -ms-flex: none;
  flex: none;
  padding-right: 5px;
}

.rbc-day-slot .rbc-event-content {
  width: 100%;
  word-wrap: break-word;
  height: 100%;
  min-height: 1em;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  line-height: 1;
}

.rbc-day-slot .rbc-time-slot {
  border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter, .rbc-time-view-resources .rbc-time-header-gutter {
  z-index: 10;
  background-color: #fff;
  border-right: 1px solid #ddd;
  margin-right: -1px;
  position: sticky;
  left: 0;
}

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px;
}

.rbc-time-view-resources .rbc-header, .rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  -ms-flex-preferred-size: 0 px;
  flex-basis: 0 px;
}

.rbc-time-header-content + .rbc-time-header-content {
  margin-left: -1px;
}

.rbc-time-slot {
  -ms-flex: 1 0 0;
  flex: 1 0 0;
}

.rbc-time-slot.rbc-now {
  font-weight: bold;
}

.rbc-day-header {
  text-align: center;
}

.rbc-slot-selection {
  z-index: 10;
  color: #fff;
  width: 100%;
  background-color: rgba(0, 0, 0, .5);
  padding: 3px;
  font-size: 75%;
  position: absolute;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  width: 100%;
  min-height: 0;
  border: 1px solid #ddd;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
}

.rbc-time-view .rbc-time-gutter {
  white-space: nowrap;
  text-align: right;
}

.rbc-time-view .rbc-allday-cell {
  box-sizing: content-box;
  width: 100%;
  height: 100%;
  position: relative;
}

.rbc-time-view .rbc-allday-cell + .rbc-allday-cell {
  border-left: 1px solid #ddd;
}

.rbc-time-view .rbc-allday-events {
  z-index: 4;
  position: relative;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: 20px;
}

.rbc-time-header {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex: none;
  flex: none;
  display: -ms-flexbox;
  display: flex;
}

.rbc-time-header.rbc-overflowing {
  border-right: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
  border-left: 1px solid #ddd;
  border-right-width: 0;
}

.rbc-time-header > .rbc-row:first-child, .rbc-time-header > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  min-width: 0;
  border-left: 1px solid #ddd;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  display: -ms-flexbox;
  display: flex;
}

.rbc-rtl .rbc-time-header-content {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-header-content > .rbc-row.rbc-row-resource {
  border-bottom: 1px solid #ddd;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.rbc-time-content {
  width: 100%;
  border-top: 2px solid #ddd;
  -ms-flex: 1 0;
  flex: 1 0;
  -ms-flex-align: start;
  align-items: flex-start;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow-y: auto;
}

.rbc-time-content > .rbc-time-gutter {
  -ms-flex: none;
  flex: none;
}

.rbc-time-content > * + * > * {
  border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-content > * + * > * {
  border-left-width: 0;
  border-right: 1px solid #ddd;
}

.rbc-time-content > .rbc-day-slot {
  width: 100%;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.rbc-current-time-indicator {
  z-index: 3;
  height: 1px;
  pointer-events: none;
  background-color: #74ad31;
  position: absolute;
  left: 0;
  right: 0;
}

/*# sourceMappingURL=index.5a50d962.css.map */
